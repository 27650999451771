<template>
  <div>
    <h1>Request a free demo or call</h1>

    <p>Request a free 15-30 minute demo or call using the form below, or simply email us at <a :href="`mailto:${tenant.email}`">{{tenant.email}}</a>.</p>

    <p>During the demo we can show how easy it is to go virtual with your event or concept. We can zoom into specific topics or areas or first start with the general picture.</p>

    <h3>Trusted by organizations world-wide</h3>
    <v-slide-group show-arrows v-model="currentSlideIndex">
      <v-slide-item
        v-for="(item, idx) in siteData.customers.filter(x => x.corporate)"
        :key="idx"
        v-slot="{ active, toggle }"
      >
        <a :href="item.url" target="_blank">
        <v-sheet :elevation="1" @click="toggle" :input-value="active" class="text-center pa-2 ma-2 d-flex" height="60" color="transparent" :title="item.name" style="background-color: rgba(255,255,255,0.1) !important; line-height:85px;">
          <img :src="`https://sodisp.imgix.net/web/customers/${item.id}.png?h=50&w=100`" :alt="item.name" class="d-inline-block" :style="`align-self: center; max-width:100px; max-height:50px;`"/>
        </v-sheet>
        </a>
      </v-slide-item>
    </v-slide-group>    

    <div v-if="true || now < DateTime.utc(2024, 9, 1)">
      <h3>Book Directly</h3>
      <p>Book a 30-minute demo on the day and time that works best for you.</p>
      <p>
        <v-btn color="accent" large href="https://corporatefitness.app/r/go/book-demo" target="_blank">Book now</v-btn>
      </p>
    </div>

      <h3>Request a demo or call</h3>
      <p>Looking for a demo on a different time slot or have additional questions? Leave a message using the form below, or simply email us at <a :href="`mailto:${tenant.email}`">{{tenant.email}}</a>.</p>

    <v-form lazy-validation ref="demoForm" action="https://formspree.io/f/mpzknpqw" method="POST">

      <v-text-field
        v-model="name"
        :rules="nameRules"
        label="Your Name"
        name="name"
        required
      ></v-text-field>


      <v-text-field
        v-model="org"
        :rules="orgRules"
        label="Organization Name"
        name="organization"
        required
      ></v-text-field>

      <v-text-field
        v-model="email"
        :rules="emailRules"
        label="E-mail"
        name="_replyto"
        type="email"
        required
      ></v-text-field>

      <v-text-field
        v-model="phone"
        :rules="phoneRules"
        label="Phone number (optional)"
        name="phone"
        type="tel"
        required
      ></v-text-field>

      <v-text-field v-model="meetingDate" label="Date and time preference" name="when" hint="When would you prefer to have this demo? Please include your time zone." />

      <v-textarea v-model="comments" class="mb-8" label="Specific topics, features or comments:" persistent-hint hint="Let us know if you have specific questions or features you'd like to include in the demo." name="comments"></v-textarea>
      <input type="hidden" name="timezone" :value="timeZoneOlson"/>
    
      <v-btn class="primary" x-large @click="submit('demo', $refs.demoForm)">Submit</v-btn>
    </v-form>
  </div>
</template>


<script>
import siteData from '@/data/site.json'
import { DateTime } from 'luxon'
import platformService from "@/services/platformService";
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "Contact",
  props: {
  },
  data: function() {
    return {
      tenant: tenant,
      siteData: siteData,
      now: DateTime.now(),
      DateTime: DateTime,
      currentSlideIndex: 0,
      interval: 0,
      valid: true,
      timeZoneOlson: null,
      name: '',
      org: '',
      phone: '',
      nameRules: [
        v => !!v || 'Name is required',
      ],
      orgRules: [
        v => !!v || 'Please enter the name of your organization',
      ],
      phoneRules: [
        v => !!v || 'Please enter a phone number where we can reach you',
      ],
      email: '',
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      comments: '',
      meetingDate: '',
    };
  },
  created() {
    var maxIndex = siteData.customers.filter(x => x.corporate).length;
    this.interval = setInterval(() => this.currentSlideIndex > maxIndex ? this.currentSlideIndex = 0 : this.currentSlideIndex++, 1000);
  },
  beforeDestroy () {
    clearInterval(this.interval);
  },
  mounted() {
    this.timeZoneOlson = DateTime.now().zoneName;
  },
  methods: {
    async submit(formName, form) {
      if (form && form.validate()) {
        try {
          this.$analytics.event('contact_submit', { 'event_category': 'contact', 'event_label': formName });
          this.$analytics.event('generate_lead', {
            currency: 'USD',
            value: 0, // unknown value
          });

          let request = {
            tenant_id: tenant.id,
            name: this.name,
            org: this.org,
            email: this.email,
            phone: this.phone,
            comments: this.comments,
            time_zone: this.timeZoneOlson,
            meeting_date: this.meetingDate,
          };
          let response = await platformService.postForm(formName, request);
          if (response && response.data) {
            this.formResult = response.data;
          }
        }
        catch {}

        form.$el.submit();
      }
    },
  },
  watch: {
  }
};
</script>

<style scoped>
</style>